const angular = window.angular;
const { formatDateTime, dateFormat, CURRENCY_SYMBOL } = window.wisenetwork

export const templateUrl = typeof window.wisenetwork !== 'undefined' ? window.wisenetwork.ASSETS_SRC : '';

export const allowAllResources = [
    '$sceDelegateProvider', 
    function(sce) {
        sce.resourceUrlWhitelist(['**']);
    }
]

export const safeResource = [
    '$sceDelegateProvider',
    function(sce) {
        sce.resourceUrlWhitelist([
            // Allow same origin resource loads.
            'self',
            // Allow loading from wisenetwork cdn
            'https://cdn.wisenetwork.fi/**',
            'https://test-cdn.wisenetwork.fi/**'
        ]);
    }
]

export const tplDefaults = [
    '$rootScope',
    function(rootScope) {
        rootScope.CURRENCY_SYMBOL = CURRENCY_SYMBOL || '€';
        
        Object.keys(dateFormat).forEach(key => {
            rootScope[key] = dateFormat[key];
        })
    }
]

export function angularModule(moduleName, moduleInject) {
    return angular
	.module(moduleName, moduleInject)
	.config(safeResource)
	.run(tplDefaults)
        .filter('wnDateFormat', function() {
            return (time, format) => {
                // validate somehow?
                return formatDateTime(format, time);
            } 
        })
}